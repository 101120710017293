body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


main {
  padding: 0.5em 0;
}

/*
@media screen and (min-width: 50em) and (max-width: 75em) {
  main {
    padding: 0.5em 7.5em 0;
  }
}

@media screen and (min-width: 75em) {
  main {
    padding: 0.5em 12.5em 0;
  }
}
*/

:root {
  --mdc-theme-primary: #212121;
  --mdc-theme-secondary: #F57C00;
}

::-webkit-scrollbar {
  display: none;
}
